<template>
  <material-chart-card
    :color="chart.color"
    :data="chart.data"
    :options="options"
    :responsive-options="chart.responsiveOptions"
    :title="chart.title"
    :type="chart.type"
  >
    <template #subtitle>
      <div class="font-weight-light text--secondary">
        <i class="mdi mdi-arrow-up indigo--text darken-1"></i
        ><span class="indigo--text darken-1">55%</span>&nbsp;词汇量增长
      </div>
    </template>

    <template #actions>
      <v-icon class="mr-1" small> mdi-clock-outline </v-icon>

      <span
        class="text-caption grey--text font-weight-light"
        v-text="chart.time"
      />
    </template>
  </material-chart-card>
</template>

<script>
import Vue from "vue";
Vue.use(require("vue-chartist"));
import "chartist/dist/chartist.min.css";
const lineSmooth = Vue.chartist.Interpolation.cardinal({
  tension: 0,
});
export default {
  name: "reacentWordsChart",
  components: {
    materialChartCard: () => import("@/components/chart/MaterialChartCard"),
  },
  computed: {
    options() {
      let high = 0;
      this.chart.data.series.forEach((series) => {
        let max = Math.max(...series);
        if (high < max) {
          high = max;
        }
      });
      high += high / 10;
      return {
        lineSmooth,
        low: 0,
        high: high, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      };
    },
  },
  data: () => ({
    chart: {
      type: "Bar",
      color: "indigo lighten-5",
      title: "单词学习",
      time: "此每日同步一次",
      data: {
        labels: ["今天", "昨天", "前天", "3天前", "4天前", "5天前", "6天前"],
        series: [[230, 250, 350, 300, 280, 360, 370]],
      },
    },
  }),
};
</script>

<style lang="scss" scoped>
::v-deep .ct-series-a .ct-bar {
  /* Colour of your bars */
  stroke: #7986cb !important;
  /* Maybe you like round corners on your bars? */
  //stroke-linecap: round;
}
::v-deep .ct-grid {
  stroke: #c5cae9 !important;
}
::v-deep .ct-label {
  color: #303f9f !important;
}
::v-deep .v-card--material__title {
  font-size: 1.15rem !important;
  letter-spacing: 0.15rem !important;
}
::v-deep .ct-label {
  font-size: 0.8rem !important;
  margin-top: 0.1rem;
}
</style>
